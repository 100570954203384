<template>
    <v-container>
        <div v-if="mode && !['edit', 'create'].includes(mode)" class="edit-button-wrapper">
            <v-btn class="mx-2" dark medium color="#004cff" @click="onEdit">
                <v-icon dark>mdi-account-edit</v-icon>
            </v-btn>
        </div>
        <v-form :readonly="readOnly">
            <v-text-field
                v-model="user.email"
                label="Email"
                :rules="rules"
                :clearable="clearable"
            />
            <v-text-field
                v-model="newPassword"
                :rules="rules"
                label="Password"
                :clearable="clearable"
            />
            <v-autocomplete
                v-model="user.dealer"
                :items="dealers"
                :item-text="getItemText"
                menu-props="auto"
                label="Select Dealer"
                hide-details
                prepend-icon="mdi-account"
                single-line
                :clearable="!readOnly"
                return-object
                deletable-chips
                chips
            />
        </v-form>
        <div v-if="mode !== 'show'" class="submit-button-wrapper">
            <v-btn class="mx-2 white--text" large :disabled="shouldDisableSubmit()" color="#004cff" @click="onSubmit">
                Submit
            </v-btn>
        </div>
    </v-container>
</template>

<script>
    import userService from '@/services/user-service'
    import dealerService from '@/services/dealer-service'

    export default {
        name: 'UserForm',
        data: () => {
            return {
                mode: null,
                readOnly: false,
                clearable: true,
                dealers: [],
                user: {
                    email: '',
                    password: '',
                    dealer: null
                },
                newPassword: '',
                userId: null,
                rules: [
                    v => !!v || 'Field is required'
                ]
            }
        },
        created() {
            this.mode = this.$route.params.mode
            this.fetchAllDealers()
            if (this.mode === 'create') {
                return
            } else if (this.mode === 'show') {
                this.readOnly = true
                this.clearable = false
            }
            this.userId = this.$route.params.userId
            if (this.userId) {
                userService.getUserById(this.userId).then(resp => {
                    this.user.email = resp.data.user.email
                    this.user.partner = resp.data.user.partner
                })
            }
        },
        methods: {
            getItemText(item) {
                return `${item.firstName} ${item.lastName}`
            },
            async onSubmit() {
                if (this.user.dealer) {
                    this.user.dealer = this.user.dealer.id
                }
                this.user.password = this.newPassword
                if (this.mode === 'edit') {
                    this.editUser()
                } else if (this.mode === 'create') {
                    this.createUser()
                }
            },
            async createUser() {
                const response = await userService.createUser(this.user)
                if (response.data.id) {
                    this.userId = response.data.id
                }
                this.$router.push(`/users`)
            },
            async editUser() {
                delete this.user.id
                await userService.editUserById(this.userId, this.user)
                this.$router.push(`/users`)
            },
            fetchAllDealers() {
                dealerService.getAllDealers().then((resp) => {
                    this.dealers = resp.data.data
                })
            },
            shouldDisableSubmit() {
                if (!this.validEmailForm(this.user.email) || this.newPassword === '') {
                    return true
                }
                return false
            },
            validEmailForm(email) {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(email)
            }
        }
    }
</script>

<style>
.tox .tox-editor-header {
    z-index: 0 !important;
}
.tox .tox-statusbar {
    display: none !important;
}

.tox .tox-toolbar--scrolling {
    flex-wrap: wrap !important;
}
.tox.tox-tinymce.tox-platform-touch {
    height: 40vh !important;
    min-height: 350px;
}
</style>
<style scoped>
.description-label {
    color: rgba(0, 0, 0, 0.6);
}
.description-editor {
    margin-bottom: 2vh;
}
.edit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

.img-preview {
    max-width: 70%;
    margin: 0 auto;
}

.image-input {
    padding: 20px;
    width: 100%;
    border-style: groove;
    border: #D3D3D3 solid 2px;
    border-radius: 15px 50px;
    margin-top: 1vh;
}

.green-border {
    border-color: green;
}

.delete-btn {
    display: flex;
    justify-content: flex-end;
}
</style>
